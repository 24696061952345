import { DhlDefaultValues } from './DhlDefaultValues';
import { Billing } from './Billing';

export const DHL = {
  clientEntityState: 4,
  tenantId: '',
  id: '',
  clientId: '',
  configurationName: '',
  password: '',
  courier: '',
  courierCode: 'dhl',
  integration: 'DHL',
  userName: '',
  urlAddress: '',
  billing: Billing,
  labelType: 'LBLP',
  serviceType: '',
  additionalServices: { services: [] },
  fieldsMappings: { mappings: [] },
  dhlDefaultValues: DhlDefaultValues,
};
